// Entry point for the build script in your package.json

// Responsive embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

// Grid overlay
import gridOverlay from "./lib/gridOverlay";
gridOverlay();

// Stimulus
import { Application } from "stimulus";
import ExpandableController from "./controllers/ExpandableController";
const application = Application.start();
application.register("expandable", ExpandableController);
